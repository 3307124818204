window.setTimeout(preguntarNotificaciones, 1 * 1000);

/**
 * Activa la notificación que pregunta por las notificaciones.
 */
function preguntarNotificaciones() {
    if (!('Notification' in window && navigator.serviceWorker && 'PushManager' in window)) {
        //No soporta las notificaciones y/o los mensajes push
        //No se pueden recibir mensajes, por lo tanto lo ignoramos
        return;
    }

    //Si ha pedido que no se le manden notificaciones no veolvemos a preguntar
    if (localStorage.notificaciones/*new RegExp(`(^| )notificaciones=([^;]+)`).test(document.cookie)*/) {
        return;
    }

    //Comprobamos si ya esta subscrito
    estaSubscrito().then(function (subscrito) {
        //Si ya lo esta no volvemos a preguntar
        if (subscrito) {
            return;
        }
        // Lanzamos la pregunta
        let preguntar = $("#preguntar-notificaciones");
        preguntar.find(".si").on("click", function (e) {
            e.preventDefault();
            registrarNotificaciones().then(function () {
                hide(true);
            }).catch(function () {
                hide(false);
            });

            return false;
        });
        preguntar.find(".no").on("click", function (e) {
            e.preventDefault();
            prevenirNotificacion();
            hide();
            return false;
        });
        preguntar.slideDown(400);


        //Ocultamos la pregunta
        function hide(correcto = true) {
            preguntar.children().remove();
            var icono = getIcono(correcto);
            preguntar.append(icono);
            icono.addClass("animate-border");

            window.setTimeout(function () { preguntar.slideUp(400); }, 4 * 1000);
        }
    });
}

/**
 * Guarda en localStorage que no se quieren recibir notificaciones
 */
function prevenirNotificacion() {
    localStorage.notificaciones = "no";
}

/**
 * Obtiene el svg para indicar si el resultado de la operación de consentimiento de notificaciones.
 * @param {boolean} correcto El icono a obtener, el correcto o incorrecto
 * @returns {string} Tag HTML con el icono correspondiente
 */
function getIcono(correcto) {
    if (correcto) {
        return $(`<span><svg xmlns="http://www.w3.org/2000/svg" style="isolation:isolate" width="59" height="59">
  <g fill="none" stroke-width="2" stroke="#009D00" stroke-linejoin="round" stroke-linecap="round" stroke-miterlimit="3">
    <path id="check" d="M52 6V4H1v51h51V22" vector-effect="non-scaling-stroke"/>
    <path id="border" d="M58 8L27 39 12.93 24.93" vector-effect="non-scaling-stroke"/>
  </g>
</svg></span>`);
    } else {
        return $(`<span><svg xmlns="http://www.w3.org/2000/svg" style="isolation:isolate" width="59" height="59">
  <g stroke="#980000" stroke-miterlimit="3">
    <path d="M1.475 29.5c0-15.467 12.558-28.025 28.025-28.025 15.467 0 28.025 12.558 28.025 28.025 0 15.467-12.558 28.025-28.025 28.025-15.467 0-28.025-12.558-28.025-28.025z" fill="none" vector-effect="non-scaling-stroke" stroke-width="1.9" stroke-linecap="square"/>
    <path vector-effect="non-scaling-stroke" stroke-width="2" stroke-linecap="round" d="M14.297 14.297l30.406 30.406M14.297 44.703l30.406-30.406"/>
  </g>
</svg></span>`);
    }
}

/**
 * Comienza el registro preguntando por el permiso de las notificaciones
 * @returns {Promise<void>} Una promesa que retorna vacio
 */
function registrarNotificaciones() {
    return Notification.requestPermission().then(function (status) {
        if (status === 'granted') {
            console.log('[Notification.requestPermission] Notification permission granted.');
            subscribe();
        } else {
            //Si lo deniega no podemos hacer mas, preguntar otra vez no serviria de nada
            console.warn('[Notification.requestPermission] Browser denied permissions to notification api.');
            if (status === "denied") {
                prevenirNotificacion();
            }
            throw "No notification";
        }
    });
}

/**
 * Realizamos la subscripcion
 */
function subscribe() {
    navigator.serviceWorker.ready.then(function (reg) {
        var subscribeParams = { userVisibleOnly: true };

        //Se obtiene la clave pública del servidor
        fetch("/api/notifications/publicKey", { credentials: 'same-origin' })
            .then(function (response) {
                //Si la respuesta es correcta terminamos, sino lanzamos un error
                if (response.status === 200) {
                    return response.text();
                } else {
                    return new Error(`Error ${response.status}`);
                }
            }).then(function (responseText) {
                var applicationServerKey = urlB64ToUint8Array(responseText);
                subscribeParams.applicationServerKey = applicationServerKey;

                //Nos subscribimos al servicio push
                reg.pushManager.subscribe(subscribeParams)
                    .then(function (subscription) {
                        console.log(subscription);
                        //Guardamos la subscripción
                        localStorage.subscription = JSON.stringify(subscription);
                    })
                    .catch(function (e) {
                        //Se produce un error al subscribirse
                        console.error('[subscribe] Unable to subscribe to push' + e);
                        throw e;
                    });
            });
    });
}


/**
 * Convierte un string en base 64 a binario
 * @param {string} base64String String en base 64 a convertir
 * @returns {Uint8Array} El contenido en binario
 */
function urlB64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

/**
 * Codifica un array buffer en base 64
 * @param {any} arrayBuffer Buffer a convertir en base64
 * @returns {string} El buffer en base64
 */
function base64Encode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)));
}

/**
 * Comprueba si el usuario esta subscrito a las notificaciones
 * @returns {boolean} Si el usuario esta subscrito o no
 */
function estaSubscrito() {
    return navigator.serviceWorker.ready.then(function (reg) {
        // Do we already have a push message subscription?
        return reg.pushManager.getSubscription()
            .then(function (subscription) {
                return subscription;
            })
            .catch(function (err) {
                console.log('[req.pushManager.getSubscription] Unable to get subscription details.', err);
                throw err;
            });
    });
}

/**
 * Da de baja a un usuario de la subscripción
 */
function darDeBaja() {
    navigator.serviceWorker.ready.then(function (reg) {
        reg.pushManager.getSubscription().then(function (subscription) {
            subscription.unsubscribe().then(function (successful) {
                localStorage.removeItem("subscription");
            }).catch(function (e) {
                // Unsubscription failed
                throw e;
            });
        });
    });
}